var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      staticStyle: { overflow: "-webkit-paged-y" },
      attrs: {
        data: _vm.transactions,
        noDataText: "Sem dados para mostrar",
        hoverFlat: "",
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(data, function (transaction, i) {
              return _c(
                "vs-tr",
                { key: i, staticStyle: { opacity: "1 !important" } },
                [
                  _c("vs-td", [_vm._v(" " + _vm._s(i + 1) + " ")]),
                  _c(
                    "vs-td",
                    [
                      _c("sig-input-currency", {
                        attrs: {
                          disabled: _vm.disabled.includes("value_parcel"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.changeValueParcel(transaction, i)
                          },
                        },
                        model: {
                          value: transaction.value_parcel,
                          callback: function ($$v) {
                            _vm.$set(transaction, "value_parcel", $$v)
                          },
                          expression: "transaction.value_parcel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: { required: true },
                            expression: "{ required: true }",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-48",
                        attrs: {
                          type: "date",
                          "data-vv-as": "Vencimento",
                          "data-vv-scope": "credit",
                          name: "expiration_date" + i,
                          disabled: _vm.disabled.includes("expiration_date"),
                        },
                        model: {
                          value: transaction.expiration_date,
                          callback: function ($$v) {
                            _vm.$set(transaction, "expiration_date", $$v)
                          },
                          expression: "transaction.expiration_date",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("expiration_date" + i),
                              expression: "errors.has('expiration_date' + i)",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Campo obrigatório")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      _c("select-type-payment", {
                        attrs: { "use-id": true },
                        model: {
                          value: transaction.type_payment_id,
                          callback: function ($$v) {
                            _vm.$set(transaction, "type_payment_id", $$v)
                          },
                          expression: "transaction.type_payment_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      _c("select-account", {
                        attrs: {
                          "use-id": true,
                          "use-filter-type-payment": true,
                          "type-payment-id": transaction.type_payment_id,
                        },
                        model: {
                          value: transaction.account_id,
                          callback: function ($$v) {
                            _vm.$set(transaction, "account_id", $$v)
                          },
                          expression: "transaction.account_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("vs-td", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap items-center justify-center",
                      },
                      [
                        _c(
                          "vs-switch",
                          {
                            staticClass: "w-28 mt-2",
                            attrs: {
                              "icon-pack": "feather",
                              "vs-icon-on": "icon-check-circle",
                              "vs-icon-off": "icon-slash",
                              disabled: _vm.disabled.includes("situation"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.changeSituation(transaction)
                              },
                            },
                            model: {
                              value: transaction.situation_bool,
                              callback: function ($$v) {
                                _vm.$set(transaction, "situation_bool", $$v)
                              },
                              expression: "transaction.situation_bool",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "off" }, slot: "off" },
                              [_vm._v("ABERTA")]
                            ),
                            _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                              _vm._v("QUITADA"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "vs-td",
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: { required: transaction.situation_bool },
                            expression:
                              "{ required: transaction.situation_bool }",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-48",
                        attrs: {
                          type: "date",
                          disabled:
                            !transaction.situation_bool ||
                            _vm.disabled.includes("payday"),
                          "data-vv-as": "Pago em",
                          "data-vv-scope": "credit",
                          name: "payday" + i,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.checkPayday(transaction)
                          },
                        },
                        model: {
                          value: transaction.payday,
                          callback: function ($$v) {
                            _vm.$set(transaction, "payday", $$v)
                          },
                          expression: "transaction.payday",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("payday" + i),
                              expression: "errors.has('payday' + i)",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Campo obrigatório")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: transaction.futureDate,
                              expression: "transaction.futureDate",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Data futura não permitida")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      _c("select-account-plan", {
                        attrs: { "use-id": true },
                        model: {
                          value: transaction.account_plan_id,
                          callback: function ($$v) {
                            _vm.$set(transaction, "account_plan_id", $$v)
                          },
                          expression: "transaction.account_plan_id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", [_vm._v("#")]),
          _c("vs-th", [_vm._v("Valor")]),
          _c("vs-th", [_vm._v("Vencimento")]),
          _c("vs-th", [_vm._v("Forma Pagamento")]),
          _c("vs-th", [_vm._v("Conta")]),
          _c("vs-th", [_vm._v("Situação")]),
          _c("vs-th", [_vm._v("Pago Em")]),
          _c("vs-th", [_vm._v("Plano de Contas")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }