<template>
  <div>
    <bank-slip-popup />
    <vs-popup
      class="popup90"
      :fullscreen="false"
      title="Cadastrar Receitas"
      :active.sync="isOpen"
    >
      <permission-password
        style="z-index: 9999999999"
        :show="popupPermissionPassword"
        title="ALERTA DE BAIXA RETROATIVA"
        icon="warning"
        actionButtonText="Prosseguir"
        @action="storeCredit(true)"
        @cancel="popupPermissionPassword = false"
      >
      </permission-password>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-0">
          <strong v-if="student">{{ student.name }}</strong>
          <student-search-select
            v-else
            name-select="credit"
            ref="studentSelect"
          />
        </div>
        <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
        <div class="vx-col md:w-1/4 w-full mt-0">
          <service-package-search-select
            name-select="credit"
            ref="servicePackageSelect"
          />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input
            class="w-full"
            label="Valor"
            ref="valueServices"
            @input="calcValues"
            v-model="value.services"
            v-currency="currencyConfig"
          />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <input-value-percent
            v-model="value.discount"
            label="Desconto"
            :currency="currencyConfig"
            @input="calcValues"
          />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Total</label>
          <h1 class="flex flex-wrap justify-center">
            <sup class="text-lg">R$</sup>
            <span>{{
              value.total
                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                .replace('R$', '')
            }}</span>
          </h1>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-3/5 w-full mt-2">
          <specie-type-payment-select
            required="true"
            ref="specieTypePaymentSelect"
          />
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <account-plan-search-select ref="accountPlanSelect" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-3/5 w-full mt-2">
          <vs-row class="w-full">
            <vs-col vs-w="3">
              <label class="vs-input--label">Nº de Parcelas</label>
              <vs-input-number
                min="1"
                class="mt-2"
                v-model="parcels.number"
                @input="changeNumberParcels"
                data-vv-as="Parcelas"
                v-validate="'required|min_value:1'"
                name="parcelsNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('parcelsNumber')"
                >{{ errors.first('parcelsNumber') }}</span
              >
            </vs-col>
            <vs-col vs-w="3">
              <vs-input
                class="w-full"
                label="Valor 1ª Parcela"
                ref="valueFirstParcel"
                :disabled="parcels.number < 2"
                v-model="parcels.first.value"
                v-currency="currencyConfig"
              />
              <span class="text-danger text-sm" v-if="errorValueFirstPacels"
                >Este valor é maior que o total.</span
              >
            </vs-col>
            <vs-col vs-w="3" class="pl-2">
              <vs-input
                type="date"
                class="mr-2 w-full"
                label="1º Vencimento"
                v-model="parcels.first.expirationDate"
                data-vv-as="1º Vencimento"
                data-vv-scope="credit"
                v-validate.initial="'required'"
                name="expirationDateFirstParcel"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('credit.expirationDateFirstParcel')"
                >Este campo é obrigatório.</span
              >
              <span
                class="text-danger text-sm"
                v-if="!isSameOrAfterToday(parcels.first.expirationDate)"
                >A primeira parcela já está vencida.</span
              >
            </vs-col>
            <vs-col vs-w="3" class="pl-2">
              <label class="vs-input--label">Periodicidade</label>
              <v-select
                v-model="parcels.frequency"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="[
                  { label: 'Mensal', value: 'monthly' },
                  { label: 'Quinzenal', value: 'biweekly' },
                  { label: 'Semanal', value: 'weekly' }
                ]"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              ></v-select>
            </vs-col>
          </vs-row>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <vs-input
            class="w-full"
            label="Descrição"
            v-model="parcels.observation"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-4/5 w-full mt-2 flex items-end">
          <span
            v-if="value.fee.value && value.addition.value"
            style="text-align: right"
            >Aplicando taxa de R$ {{ value.fee_formated }} ({{
              value.fee.percentual
            }}) e acréscimo de R$ {{ value.addition.value_formated }} ({{
              value.addition.percentual
            }}) sobre o valor dos serviços
          </span>
          <span v-else-if="value.fee.value" style="text-align: right"
            >Aplicando taxa de R$ {{ value.fee_formated }} ({{
              value.fee.percentual
            }}) sobre o valor dos serviços
          </span>
          <span v-else-if="value.addition.value" style="text-align: right"
            >Aplicando acréscimo de R$ {{ value.addition.value_formated }} ({{
              value.addition.percentual
            }}) sobre o valor dos serviços
          </span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2 flex items-end">
          <vs-button
            @click="generateParcels"
            :disabled="parcels.disable"
            class="w-full"
            type="border"
            color="warning"
            icon-pack="feather"
            icon="icon-dollar-sign"
            >Gerar Parcelas</vs-button
          >
        </div>
      </div>
      <vs-divider position="left">
        <feather-icon
          icon="DollarSignIcon"
          class="mr-0"
          svgClasses="w-4 h-4"
        /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <transaction-list :name-select="model.$name" @saved="this.popup = false"/>
    </vs-popup>
  </div>
</template>

<script>
// Store Module
import AccountPlanSearchSelect from '@/components/financial/accountPlan/AccountPlanSearchSelect.vue'
import SpecieTypePaymentSelect from '@/components/financial/specieTypePayment/SpecieTypePaymentSelect'
import StudentSearchSelect from '@/components/register/student/StudentSearchSelect'
import ServicePackageSearchSelect from '@/components/register/service/ServicePackageSearchSelect'
import TransactionList from '@/components/cashier/transaction/TransactionList.vue'
import InputValuePercent from '@/components/InputValuePercent'
import modelStudent from '@/store/register/student/model'
import model from '@/store/cashier/credit/model'
import modelSpecie from '@/store/financial/specie/model'
import modelAccountPlan from '@/store/financial/accountPlan/model'
import modelServicePackage from '@/store/register/servicePackage/model'
import modelTransaction from '@/store/cashier/transaction/model'
import BankSlipPopup from '@/components/report/pdf/BankSlipPopup.vue'

import moment from 'moment'
import {
  setValue,
  getValue,
  CurrencyDirective,
  CurrencyInput
} from 'vue-currency-input'

const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(
    /* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue'
  )

//AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
Date.isLeapYear = function (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}
Date.getDaysInMonth = function (year, month) {
  return [
    31,
    Date.isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31
  ][month]
}
Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear())
}
Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth())
}
Date.prototype.addMonths = function (value) {
  const n = this.getDate()
  this.setDate(1)
  this.setMonth(this.getMonth() + value)
  this.setDate(Math.min(n, this.getDaysInMonth()))
  // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
  return this
}

export default {
  components: {
    vSelect,
    CurrencyInput,
    permissionPassword,
    SpecieTypePaymentSelect,
    StudentSearchSelect,
    ServicePackageSearchSelect,
    AccountPlanSearchSelect,
    BankSlipPopup,
    InputValuePercent,
    TransactionList
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    student: {}
  },
  computed: {
    valueSelectedServices () {
      return this.$store.getters[
        modelServicePackage.$store.getters[`totalValueSelect${model.$name}`]
      ]
    },
    isOpen: {
      get () {
        return this.$store.state[model.$store.name].form.isOpen
      },
      set (value) {
        if (value === false) {
          this.popup = false
        }
        this.$store.commit(model.$store.commit.activeForm, {
          isOpen: value || false
        })
      }
    },
    popup: {
      get () {
        return this.$store.state[model.$store.name].form.popup
      },
      set (value) {
        this.$store.commit(model.$store.commit.activeForm, {
          popup: value
        })
      }
    },
    nameSelect: {
      get () {
        return this.$store.state[modelSpecie.$store.name].select.name
      },
      set (value) {
        this.$store.commit(modelSpecie.$store.commit.nameSelect, value)
      }
    },
    typePaymentSig () {
      return this.$store.state['typePaymentSig'].select['specie'].selected
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    valueFirstParcel () {
      return this.transactions[0] && this.transactions[0].value_parcel
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    accountPlan () {
      return this.$store.getters[
        modelAccountPlan.$store.getters[`selected${model.$name}`]
      ]
    },
    transactions: {
      get () {
        return this.$store.getters[
          modelTransaction.$store.getters[`selected${model.$name}`]
        ]
      },
      set (payload) {
        this.$store.commit(
          modelTransaction.$store.commit.changeSelected,
          payload
        )
      }
    }
  },

  watch: {
    valueSelectedServices (value) {
      setValue(this.$refs.valueServices, value)
    },
    popup (value) {
      if (value === true) {
        const me = this
        this.$store.commit('popup/add', {
          open () {
            me.isOpen = true
            me.fetchData()
          },
          close () {
            me.isOpen = null
          }
        })
      } else if (value === false) {
        this.clear()
        this.$store.commit('popup/remove')
      }
    }
  },
  data () {
    this.model = model
    return {
      value: {
        addition: {
          value: 0,
          percentual: 0
        },
        fee: {
          value: 0,
          percentual: 0
        },
        services: 0,
        discount: {
          value: 0,
          type: '%'
        },
        total: 0
      },
      parcels: {
        number: 1,
        value: 0,
        max: 1,
        frequency: 'monthly',
        first: {
          value: 0,
          expirationDate: moment().format('YYYY-MM-DD')
        },
        observation: '',
        disable: false,
        expirationDate: null
      },
      popupPermissionPassword: false,
      disableSave: false,
      errorValueFirstPacels: false,
      currencyConfig: {
        currency: { prefix: 'R$ ' },
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      }
    }
  },

  directives: { currency: CurrencyDirective },

  methods: {
    fetchData () {
      this.$store
        .dispatch('servicePackage/fetchAll')
        .then(() => {})
        .catch((err) => {
          console.error(err)
        })

      this.$store.commit(modelStudent.$store.commit.nameSelect, model.$name)
      this.$store.commit(
        modelServicePackage.$store.commit.nameSelect,
        model.$name
      )
      this.$store.commit(modelAccountPlan.$store.commit.nameSelect, model.$name)
      this.$store
        .dispatch('accountPlan/fetchAll')
        .then(() => {})
        .catch((err) => {
          console.error(err)
        })
    },
    preValidate () {
      if (this.typePaymentSig[0] && this.typePaymentSig[0].parcels.length) {
        this.parcels.max = this.typePaymentSig[0].parcels.length
      } else {
        this.parcels.number = 1
        this.parcels.max = 1
      }
      this.calcValues()
      let isValid = true
      if (!this.student && !this.$refs.studentSelect.isValid()) isValid = false
      if (!this.$refs.servicePackageSelect.isValid()) isValid = false
      if (!this.$refs.accountPlanSelect.isValid()) isValid = false
      if (!this.$refs.specieTypePaymentSelect.isValid()) isValid = false
      if (this.errors.any('credit')) isValid = false
      if (!isValid) {
        this.mixinNotify('Preencha os campos necessários')
        return false
      }
      // valor total
      return isValid
    },
    calcValues () {
      if (this.value.services === 'R$ 0,00') return // evita Error
      let value = 0
      const valueService = getValue(this.$refs.valueServices)
      // eu clono para evitar erro de alterar fora do mutation
      const typePayment =
        this.typePaymentSig[0] && this.typePaymentSig[0]
          ? this.typePaymentSig[0].$clone()
          : null
      if (typePayment) {
        const parcel = typePayment.$getParcelByNumber(this.parcels.number)
        if (parcel) {
          parcel.$setValue(valueService)
          this.value.fee.value = parcel.$fee()
          this.value.fee.percentual = parcel.fee_formated
          this.value.addition.value = parcel.$addition()
          this.value.addition.percentual = parcel.addition_formated
          value = parcel.$calculate()
        } else {
          typePayment.$setValue(valueService)
          value = typePayment.$calculate()
          this.value.fee.value = typePayment.$fee()
          this.value.addition.value = typePayment.$addition()
        }
        this.value.fee.value_formated = this.value.fee.value.toLocaleString(
          'pt-BR',
          { style: 'currency', currency: 'BRL' }
        )
        this.value.addition.value_formated =
          this.value.addition.value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })
      } else {
        this.value.fee.value = 0
        this.value.fee.value_formated = 0
        this.value.addition.value = 0
        this.value.addition.value_formated = 0
        value = valueService
      }

      if (this.value.discount.type === '%') {
        this.value.total = value * (1 - this.value.discount.value / 100)
      } else {
        this.value.total = value - this.value.discount.value
      }
      this.value.total = this.value.total.toFixed(2)

      if (parseInt(this.parcels.number) === 1) {
        setValue(this.$refs.valueFirstParcel, this.value.total)
        this.parcels.value = this.value.total
      } else {
        setValue(this.$refs.valueFirstParcel, 0)
      }

      this.parcels.value =
        (this.value.total - getValue(this.$refs.valueFirstParcel)) /
        parseInt(this.parcels.number)
    },
    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.transactions.findIndex((e) => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.transactions.findIndex((e) => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    openLoading () {
      this.$vs.loading({
        container: '#boleto-loading',
        scale: 1
      })
    },
    clear () {},
    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },
    changeNumberParcels () {
      if (!this.preValidate()) {
        this.parcels.number = 1
        return
      }
      if (this.parcels.number > this.parcels.max) {
        this.parcels.number = this.parcels.max
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Número de parcelas excedido',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
      this.transactions = []

      this.calcValues()
    },
    nextExpirationDate () {
      switch (this.parcels.frequency) {
      case 'monthly':
        this.parcels.expirationDate.add(1, 'months')
        break
      case 'biweekly':
        this.parcels.expirationDate.add(15, 'days')
        break
      case 'weekly':
        this.parcels.expirationDate.add(7, 'days')
        break
      }
      return moment(this.parcels.expirationDate).format('YYYY-MM-DD')
    },
    generateParcels () {
      if (!this.preValidate()) {
        return
      }
      this.$store.commit(modelTransaction.$store.commit.nameSelect, model.$name)
      const list = []

      list.push(
        new modelTransaction.constructor({
          type: 'CREDIT',
          // eslint-disable-next-line
          expiration_date: this.parcels.first.expirationDate,
          parcel: 1,
          number_parcel: this.parcels.number,
          value_parcel:
            getValue(this.$refs.valueFirstParcel) || this.parcels.value,
          situation: 'PENDING', // false = PENDING | true = PAID
          value_total: this.value.total,
          observation: this.parcels.observation,
          account_plan_id: this.accountPlan[0].id,
          account_plan: [this.accountPlan],
          account_id: this.typePaymentSig[0].account_id,
          account: this.typePaymentSig[0],
          type_payment_id: this.typePaymentSig[0].id,
          type_payment: this.typePaymentSig[0]
        })
      )
      this.parcels.expirationDate = moment(this.parcels.first.expirationDate)

      for (let parcel = 2; parcel <= this.parcels.number; parcel++) {
        list.push({
          type: 'CREDIT',
          parcel,
          number_parcel: this.parcels.number,
          expiration_date: this.nextExpirationDate(),
          situation: 'PENDING', // false = PENDING | true = PAID
          value_parcel: this.parcels.value,
          value_total: this.value.total,
          observation: this.parcels.observation,
          account_plan_id: this.accountPlan[0].id,
          account_plan: [this.accountPlan],
          account_id: this.typePaymentSig[0].account_id,
          account: this.typePaymentSig[0],
          type_payment_id: this.typePaymentSig[0].id,
          type_payment: this.typePaymentSig[0]
        })
      }

      this.transactions = list
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
</style>
