<template>
  <vs-popup
    class="popup90"
    :fullscreen="false"
    title="Boleto(s)"
    :active.sync="isOpen"
  >
    <div class="vs-con-loading__container">
      <embed
        :src="url"
        alt="Boleto"
        width="100%"
        height="600"
        type="application/pdf"
        pluginspage="https://www.adobe.com/products/acrobat/readstep2.html"
      />

      <div class="vx-row">
        <vs-divider></vs-divider>
        <div class="vx-col w-full mt-0">
          <div class="mt-2 flex flex-wrap items-center justify">
            <vs-button :href="deliveryUrl" class="w-full"
              >!! Clique aqui para baixar o arquivo de remessa !!</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
// Store Module
import model from '@/store/report/bankSlip/model'

export default {
  computed: {
    url () {
      return this.$store.getters[model.$store.getters.url]
    },
    deliveryUrl () {
      return this.$store.getters[model.$store.getters.deliveryUrl]
    },
    isOpen: {
      get () {
        return this.$store.getters[model.$store.getters.form]
      },
      set (value) {
        if (value === false) {
          this.popup = false
        }
        this.$store.commit(model.$store.commit.activeForm, {
          isOpen: value || false
        })
      }
    },
    popup: {
      get () {
        return this.$store.getters[model.$store.getters.popup]
      },
      set (value) {
        this.$store.commit(model.$store.commit.activeForm, {
          popup: value
        })
      }
    }
  },
  watch: {
    popup (value) {
      if (value === true) {
        const me = this
        this.$store.commit('popup/add', {
          open () {
            me.isOpen = true
          },
          close () {
            me.isOpen = null
          }
        })
      } else if (value === false) {
        this.$store.commit('popup/remove')
      }
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
</style>
