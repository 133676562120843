<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">Plano de contas</label>
      <v-select
        v-if="debit"
        class="vue_select_drop_size_180"
        v-model="account_plan_id"
        name="accountPlan"
        v-validate.initial="'required'"
        data-vv-as="Plano de Conta"
        @input="inputSelect"
        :reduce="(option) => option.value"
        :options="receipt"
        placeholder="Selecione"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}">
          Desculpe, nenhum resultado encontrado.
        </template>
      </v-select>
       <v-select
        v-else
        class="vue_select_drop_size_180"
        v-model="account_plan_id"
        name="accountPlan"
        v-validate.initial="'required'"
        data-vv-as="Plano de Contas"
        @input="inputSelect"
        :reduce="(option) => option.value"
        :options="receipt"
        placeholder="Selecione"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}">
          Desculpe, nenhum resultado encontrado.
        </template>
      </v-select>
       <span
        class="text-danger text-sm"
        v-show="errors.has('accountPlan')"
        >{{ errors.first('accountPlan') }}</span
      >
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import model from '@/store/financial/accountPlan/model'

export default {
  props: ['debit'],
  data () {
    return {
      account_plan_id: null
    }
  },
  computed: {
    receipt () {
      return this.$store.getters['accountPlan1/forSelectFilterReceipt']
    }
  },
  watch: {
    receipt () {
      this.autoSelect(this.type === 'debit' ? 'DESPESA' : 'RECEITA')
    }
  },
  methods: {
    autoSelect (name) {
      if (!this.account_plan_id) {
        const list = this.receipt.filter(element => {
          return element.plan === name
        })

        if (list.length > 0) {
          this.account_plan_id = list[0].id
          this.$store.commit(
            model.$store.commit.changeSelected,
            this.receipt.filter((element) => {
              return this.account_plan_id === element.id
            })
          )
        }
      }
    },
    inputSelect () {
      this.$store.commit(
        model.$store.commit.changeSelected,
        this.receipt.filter((element) => {
          return this.account_plan_id === element.id
        })
      )
      this.$emit('selected')
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('accountPlan')
    }
  }
}
</script>