<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">Serviços</label>
      <v-select
        v-model="service"
        @input="inputSelect"
        @open="$emit('open-sigselect')"
        data-vv-as="Serviços"
        v-validate.initial="'required'"
        name="service"
        :options="options"
        multiple
        placeholder="Selecione"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span
        class="text-danger text-sm"
        v-show="errors.has('service')"
        >{{ errors.first('service') }}</span
      >
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import model from '@/store/register/servicePackage/model'

export default {
  props: ['nameSelect'],
  data () {
    return {
      service: null
    }
  },
  computed: {
    options () {
      return this.$store.getters['servicePackage1/forSelect']
    }
  },
  methods: {
    inputSelect () {
      this.$store.commit(model.$store.commit.changeSelected, this.service.map(element => { return element.data }))
      this.$emit('selected')
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('service')
    }
  }
  
}
</script>