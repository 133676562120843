<template>
  <vs-table
    :data="transactions"
    noDataText="Sem dados para mostrar"
    hoverFlat
    style="overflow: -webkit-paged-y"
  >
    <template slot="thead">
      <vs-th>#</vs-th>
      <vs-th>Valor</vs-th>
      <vs-th>Vencimento</vs-th>
      <vs-th>Forma Pagamento</vs-th>
      <vs-th>Conta</vs-th>
      <vs-th>Situação</vs-th>
      <vs-th>Pago Em</vs-th>
      <vs-th>Plano de Contas</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr :key="i" v-for="(transaction, i) in data" style="opacity: 1 !important">
        <vs-td> {{ i + 1 }} </vs-td>
        <vs-td>
          <!-- valor da parcela -->
          <sig-input-currency v-model="transaction.value_parcel" :disabled="disabled.includes('value_parcel')" @input="changeValueParcel(transaction, i)" />
        </vs-td>
        <vs-td>
          <vs-input
            type="date"
            class="w-48"
            v-model="transaction.expiration_date"
            data-vv-as="Vencimento"
            data-vv-scope="credit"
            v-validate.initial="{ required: true }"
            :name="'expiration_date' + i"
            :disabled="disabled.includes('expiration_date')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('expiration_date' + i)"
            >Campo obrigatório</span
          >
        </vs-td>
        <vs-td>
          <select-type-payment v-model="transaction.type_payment_id" :use-id="true" />
        </vs-td>
        <vs-td>
          <select-account
          v-model="transaction.account_id"
          :use-id="true"
          :use-filter-type-payment="true"
          :type-payment-id="transaction.type_payment_id"
          />
        </vs-td>
        <vs-td>
          <div class="flex flex-wrap items-center justify-center">
            <vs-switch
              class="w-28 mt-2"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              @input="changeSituation(transaction)"
              v-model="transaction.situation_bool"
               :disabled="disabled.includes('situation')"
            >
              <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
            </vs-switch>
          </div>
        </vs-td>
        <vs-td>
          <vs-input
            type="date"
            class="w-48"
            :disabled="!transaction.situation_bool || disabled.includes('payday')"
            v-model="transaction.payday"
            @change="checkPayday(transaction)"
            data-vv-as="Pago em"
            data-vv-scope="credit"
            v-validate.initial="{ required: transaction.situation_bool }"
            :name="'payday' + i"
          />
          <span class="text-danger text-sm" v-show="errors.has('payday' + i)"
            >Campo obrigatório</span
          >
          <span class="text-danger text-sm" v-show="transaction.futureDate"
            >Data futura não permitida</span
          >
        </vs-td>
        <vs-td>
            <select-account-plan v-model="transaction.account_plan_id" :use-id="true" />
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
// Store Module
import SelectTypePayment from '@/components/financial/typePayment/SelectTypePayment.vue'
import SelectAccount from '@/components/financial/account/SelectAccount.vue'
import SelectAccountPlan from '@/components/financial/accountPlan/SelectAccountPlan.vue'
import SigInputCurrency from '@/components/sig/SigInputCurrency.vue'
import moment from 'moment'
import {NumberSig} from '@/util/NumberSig'

export default {
  components: {
    SelectTypePayment,
    SelectAccount,
    SelectAccountPlan,
    SigInputCurrency
  },
  props: {
    value: {
      default () {
        return []
      }
    },
    totalValue: {
    },
    disabled:{
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      localTransactions: []
    }
  },
  computed: {
    transactions: {
      get () {
        return this.value.map(e => {
          const i = e
          i.situation_bool = e.situation === 'PAID'
          i.payday = e.payday ? moment(i.payday).format('YYYY-MM-DD') : e.payday
          return i
        })
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeSituation (parcel) {
      parcel.situation = parcel.situation_bool ? 'PAID' : 'PENDING'
      if (parcel.situation === 'PAID') {
        parcel.value_pay = parcel.value_parcel
        parcel.payday = parcel.expiration_date
        this.checkPayday(parcel)
      } else {
        parcel.value_pay = 0
        parcel.payday = ''
      }
    },
    changeValueParcel (parcel, idxParcel) {
      parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0
      if (this.totalValue > 0) {
        this.calculateNextParcels(idxParcel)
        this.transactions = this.transactions.map(e => e)
      }
    },
    calculateNextParcels (idxParcel) {
      let idx = 0
      let partialValue =  this.totalValue
      let quantity = this.transactions.length - idxParcel - 1
      while (idx <= idxParcel) {
        partialValue -= NumberSig.round(this.transactions[idx].value_parcel)
        idx++
      }
      partialValue = NumberSig.round(partialValue / quantity)

      while (idx < this.transactions.length) {
        this.transactions[idx].value_parcel = partialValue
        idx++
      }
      quantity = this.transactions.length - 1
      partialValue = 0
      for (let i = 0; i < quantity; i++) {
        partialValue += this.transactions[i].value_parcel
      }

      this.transactions[quantity].value_parcel = NumberSig.round(this.totalValue - partialValue)
    },
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(), 'days')
        if (difDays > 0) {
          parcel.$futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.transactions.findIndex(e => e.$futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.$futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.credit.transactions.findIndex(e => e.$futureDate)
        if (index === -1) this.disableSave = false
      }
    }

  },
  mounted () {
    for (let i = 0; i < this.transactions.length; i++) {
      if (!this.transactions[i].parcel || !this.transactions[i].parcel.situation) continue
      if (this.transactions[i].parcel.situation === 'PAID') this.transactions[i].situation_bool = true
      else this.transactions[i].parcel.situation = false
    }
  }
}
</script>
