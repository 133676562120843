var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      staticClass: "popup90",
      attrs: { fullscreen: false, title: "Boleto(s)", active: _vm.isOpen },
      on: {
        "update:active": function ($event) {
          _vm.isOpen = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vs-con-loading__container" }, [
        _c("embed", {
          attrs: {
            src: _vm.url,
            alt: "Boleto",
            width: "100%",
            height: "600",
            type: "application/pdf",
            pluginspage:
              "https://www.adobe.com/products/acrobat/readstep2.html",
          },
        }),
        _c(
          "div",
          { staticClass: "vx-row" },
          [
            _c("vs-divider"),
            _c("div", { staticClass: "vx-col w-full mt-0" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center justify" },
                [
                  _c(
                    "vs-button",
                    { staticClass: "w-full", attrs: { href: _vm.deliveryUrl } },
                    [
                      _vm._v(
                        "!! Clique aqui para baixar o arquivo de remessa !!"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }