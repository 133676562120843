<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">Aluno</label>
      <v-select
        v-model="student"
        @search="debouncedGetSearchStudent"
        @input="inputSelect"
        @open="$emit('open-sigselect')"
        :clearable="false"
        :filterable="false"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Aluno"
        v-validate.initial="'required'"
        name="student"
        placeholder="Digite CPF ou nome do aluno..."
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('student')">{{
        errors.first('student')
      }}</span>
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import axios from '@/axios.js'
import _ from 'lodash'
import model from '@/store/register/student/model'

export default {
  props: ['nameSelect'],
  data () {
    return {
      student: null,
      options: []
    }
  },
  methods: {
    inputSelect () {
      this.$store.commit(model.$store.commit.selected, {
        data: this.student
      })
    },
    fetchOptions (search, loading) {
      if (search.length) {
        loading(true)
        axios
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: 'STUDENT'
          })
          .then(
            function (response) {
              this.options = []
              for (const k in response.data) {
                this.options.push({
                  value: new model.constructor(response.data[k]),
                  label: `${response.data[k].name} ${
                    response.data[k].cpf || ''
                  }`
                })
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('student')
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchOptions, 600)
  }
}
</script>
